import axios from 'axios';

export const log = async (text, log_type, boxId = null, options = {}) => {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : options.user;
  if (!user) {
    return;
  }
  let type = null;
  switch (log_type) {
    case 'info':
      type = 1;
      break;
    case 'error':
      type = 2;
      break;
    case 'status':
      type = 3;
      break;
    case 'topup':
      type = 4;
      break;
    default:
  }
  options.user = null;
  const params = {
    box_id: boxId,
    text: text,
    user_id: user?._id,
    log_type: type,
    ...options,
  };

  try {
    const res = await axios.post('/logs', params);
    if (res.data.success) {
      return;
    }
    throw new Error(res.data.error);
  } catch (e) {
    console.error(e);
  }
};

export const stockOperationLog = async (text, boxId = null, options = {}) => {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : options.user;
  if (!user) {
    return;
  }
  options.user = null;
  const params = {
    box_id: boxId,
    text: text,
    user_id: user?._id,
    ...options,
  };

  try {
    const res = await axios.post('/stock_operation_logs', params);
    if (res.data.success) {
      return;
    }
    throw new Error(res.data.error);
  } catch (e) {
    console.error(e);
  }
};
