import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { stockOperationLog } from '../../../utils/Logger.js';
import axios from 'axios';
import { OsgSelect, OsgButton } from '../../Common/UIcomponents';
import {
  makeStyles,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  CircularProgress
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  listBox: {
    width: "80vw",
  },
  listColumn: {
    display: "flex",
    maxWidth: 1000,
  },
  header: {
    fontSize: '18px'
  },
  listName: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "20%",
    textAlign: "left",
    fontWeight: "bold",
    paddingLeft: "30px"
  },
  listValue: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "60%"
  },
  formControl: {
    width: "80%"
  },
  radioContainer: {
    width: "100%",
    backgroundColor: "#F6FBFF"
  },
  saveContainer: {
    marginTop: 100,
    marginBottom: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const OneByOneForm = props => {
  const [t] = useTranslation();
  const classes = useStyles();
  const today = moment().format(t('YYYY年MM月DD日(ddd)'));
  const [user, setUser] = useState({});
  const customerList = props.customerList;
  const [boxList, setBoxList] = useState(null);
  const [customerId, setCustomerId] = useState('');
  const [boxId, setBoxId] = useState('');
  const [item, setItem] = useState(null);
  const [edpNo, setEdpNo] = useState('');
  const [type, setType] = useState('plus');
  const [plusCount, setPlusCount] = useState(0);
  const [minusCount, setMinusCount] = useState(0);
  const [pulldownError, setPulldownError] = useState(false);
  const [itemError, setItemError] = useState(false);
  const [edpNoError, setEdpNoError] = useState(false);
  const [reorderPoint, setReorderPoint] = useState(null);

  const details = [
    { name: t("分類"), value: item?.category },
    { name: t("ツールNo") + '.', value: item?.edp_no },
    { name: t("製品記号"), value: item?.abbreviation },
    { name: t("呼び"), value: item?.designation || '-' },
    { name: t("刃数 / 溝数"), value: item?.number_of_flutes },
    { name: t("精度"), value: item?.grade || '-' },
    { name: t("表面処理"), value: item?.surface_treatment || '-' },
    { name: t("重量"), value: item?.weight },
    { name: t("全長・ねじ長・シャンク径"), value: item?.designation },
    { name: t("材質"), value: item?.materials || '-' },
    { name: t("推奨下穴径"), value: item?.drill_hole_dia || '' },
    { name: t("在庫ロケーション"), value: item?.location || '-' },
    { name: t("備考"), value: item?.remarks || '-' },
    { name: t("標準価格/1点"), value: item?.standard_price || '-' },
    { name: t("現在のBOX在庫数"), value: item?.quantity || '-' },
  ];

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')));
    setBoxList([]);
  }, []);

  const getItem = async () => {
    if (!boxId || !customerId) {
      setPulldownError(true)
    } else if (!edpNo) {
      setEdpNoError(true)
    } else {
      setEdpNoError(false)
      setPulldownError(false)
      const res = await axios.get(`/items/in_box/${ boxId }/${ edpNo }`);
      setItem(res.data);
      setReorderPoint(res.data?.reorder_point);
      if (!res.data) {
        setItemError(true)
      } else {
        setItemError(false)
      }
    }
  };

  const handleSetCustomerId = async (event) => {
    setCustomerId(event.target.value);
    setBoxList(null);
    const res = await axios.get(`/boxes/customer/${event.target.value}`);
    setBoxList(res.data);
  };

  const handleSetBoxId = (event) => {
    setBoxId(event.target.value);
  };

  const handleSave = async () => {
    let options = {};
    const res = await axios.put(`/items/count/${ boxId }/${ item._id }`, {
      type: type,
      count: type === 'plus' ? plusCount : minusCount,
      reorder_point: reorderPoint
    });
    let description = '';
    if (res.status === 200 && res.data.success === true) {
      props.history.push({ pathname: '/stocks', state: { customer_id: customerId, box_id: boxId }});
      let tmpItem = item;
      tmpItem.refill_quantity = type === 'plus' ? plusCount : - minusCount
      const logRefillQuantity = type === 'plus' ? plusCount : minusCount
      options.items = [tmpItem];
      if (type === 'plus' && plusCount > 0) {
        stockOperationLog(`在庫数量 手入力更新/ツールNo:${tmpItem.edp_no} ${logRefillQuantity}点 補充`, boxId, options);
        description = t('補充データを反映しました');
      } else if (type === 'minus' && minusCount > 0){
        stockOperationLog(`在庫数量 手入力更新/ツールNo:${tmpItem.edp_no} ${logRefillQuantity}点 撤去`, boxId, options);
        description = t('撤去データを反映しました');
      }
      if (item.reorder_point !== reorderPoint) {
        stockOperationLog(`発注点 手入力更新/ツールNo:${tmpItem.edp_no} ${reorderPoint}点 更新`, boxId, options);
        description = `${description}
${t('発注点を変更しました')}`;
      }
      alert(description);
    }
  };

  return (
    <>
      <div className={classes.listBox}>
        <dl>
          <div className={classes.listColumn} style={{marginTop: "20px"}}>
            <dt className={classes.listName}>
              <h2 className={classes.header}>{t('手入力で補充する')}</h2>
            </dt>
          </div>
          <div className={classes.listColumn} style={{marginTop: "20px"}}>
            <dt className={classes.listName}>{t('補充日')}</dt>
            <dd className={classes.listValue} style={{width: "20%"}}>{today}</dd>
            <span className={classes.listValue} style={{width: "40%", fontSize: "12px", color: "#427DD4"}}>
              {t('※登録後、BOXの製品補充・撤去は、速やかに当日行ってください')}
            </span>
          </div>

          <div className={classes.listColumn} style={{marginTop: "20px"}}>
            <dt className={classes.listName}>{t('補充者名')}</dt>
            <dd className={classes.listValue}>{user.username}</dd>
          </div>
        </dl>
      </div>

      <div className={classes.listBox}>
        <div className={classes.listColumn}>
          <dt className={classes.listName}>{t('会社名')}</dt>
          <dd className={classes.listValue}>
            <FormControl className={classes.formControl}>
              <OsgSelect
                value={customerId}
                onChange={handleSetCustomerId}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                style={{width: '254px'}}
              >
                <option value=""> - </option>
                {customerList.map((row) => {
                  return <option key={ row._id } value={row._id}>{row.name}</option>;
                })}
              </OsgSelect>
            </FormControl>
          </dd>
        </div>

        <div className={classes.listColumn} style={{marginTop: "20px"}}>
          <dt className={classes.listName}>{t('補充するBOXを選択')}</dt>
          <dd className={classes.listValue}>
            {(boxList) ? (
              <FormControl className={classes.formControl}>
                <OsgSelect
                  value={boxId}
                  onChange={handleSetBoxId}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  style={{width: '254px'}}
                >
                  <option value=""> - </option>
                  {boxList.map((row) => {
                    return <option key={ row._id } value={row._id}>{row.name}</option>;
                  })}
                </OsgSelect>
              </FormControl>
            ) : (
              <div style={{ width: "254px", justifyContent: 'center', display: 'flex'}}>
                <CircularProgress size="2rem" />
              </div>
            )}
          </dd>
        </div>

        { !pulldownError ?
          <></>
        :
          <div className={classes.listColumn}>
            <dt className={classes.listName}></dt>
            <dd className={classes.listValue} style={{fontSize: "12px", color: "#C90000"}}>
            {t('会社名、または補充するBOXを選択してください')}
            </dd>
          </div>
        }
      </div>

      <div className={classes.listBox} style={{marginTop: "20px"}}>
        <div className={classes.listColumn}>
          <dt className={classes.listName}></dt>
          <dd className={classes.listValue} style={{fontSize: "12px", color: "#427DD4"}}>
          {t('ツールNo. を入力し、過去に製品情報の登録があったかどうかを確認します。')}
          </dd>
        </div>

        <div className={classes.listColumn}>
          <dt className={classes.listName}></dt>
          <dd className={classes.listValue}>
            <TextField type="text" onChange={ e => setEdpNo(e.target.value) } style={{backgroundColor: "#D4E7FF", marginRight: 20, width: "254px"}} />
            <OsgButton onClick={ getItem } style={{width: "80px"}}>{t('表示')}</OsgButton>
          </dd>
        </div>
        { !itemError ?
          <></>
        :
          <div className={classes.listColumn}>
            <dt className={classes.listName}></dt>
            <dd className={classes.listValue} style={{fontSize: "12px", color: "#C90000"}}>
            {t('このツールナンバーは過去に登録されていません。新規登録の場合はCSVよりアップロードしてください。')}
            </dd>
          </div>
        }
        { !edpNoError ?
          <></>
        :
          <div className={classes.listColumn}>
            <dt className={classes.listName}></dt>
            <dd className={classes.listValue} style={{fontSize: "12px", color: "#C90000"}}>
            {t('ツールNo.を入力してください。')}
            </dd>
          </div>
        }
      </div>
      { item ? <>
        <h4>{t('製品情報')}</h4>
        {details.map((v,index) => {
          return (
            <div className={classes.listColumn} key={index} style={{marginLeft: 50}}>
              <dt className={classes.listName} style={{justifyContent: "flex-start"}}>{v.name}</dt>
              <dd className={classes.listValue}>{v.value}</dd>
            </div>
          );
        })}

        <div style={{marginTop: 100}}>
          <div className={classes.listColumn} style={{maxWidth: "100%"}}>
            <div className={classes.listName} style={{justifyContent: "flex-start", width: "20%"}}>
              {t('発注点')}
            </div>
            <div className={classes.listValue} style={{width: "80%", justifyContent: "flex-start", alignItems: "start"}}>
              <div className={classes.radioContainer}>
                <TextField value={reorderPoint} style={{width: "200px", backgroundColor: "#D4E7FF"}} onChange={ e => setReorderPoint(e.target.value)} disabled={type === 'minus'} />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className={classes.listColumn} style={{maxWidth: "100%"}}>
            <div className={classes.listName} style={{justifyContent: "flex-start", width: "20%"}}>
            {t('製品の補充・撤去')}
            </div>
            <div className={classes.listValue} style={{width: "80%", justifyContent: "flex-start", alignItems: "start"}}>
              <RadioGroup
                row
                aria-label="position"
                name="type"
                defaultValue="plus"
                onChange={(e) => setType(e.target.value)}
                style={{width: "100%"}}
              >
                <div className={classes.radioContainer}>
                  <FormControlLabel
                    value="plus"
                    control={<Radio color="primary" />}
                    label={t("補充")}
                    labelPlacement="end"
                    style={{width: "150px"}}
                  />
                  <span style={{marginLeft: 30, marginRight: 20}}>{t('補充する個数')}</span>
                  <TextField style={{width: "200px", backgroundColor: "#D4E7FF"}} onChange={ e => setPlusCount(e.target.value)} disabled={type === 'minus'} />
                  <span style={{marginLeft: 30, color: "#427DD4", fontSize: "12px"}}>{t('※補充する個数を入力します')}</span>
                </div>

                <div className={classes.radioContainer} style={{marginTop: 10}}>
                  <FormControlLabel
                    value="minus"
                    control={<Radio color="primary" />}
                    label={t("撤去")}
                    labelPlacement="end"
                    style={{width: "150px"}}
                  />
                  <span style={{marginLeft: 30, marginRight: 20}}>{t('撤去する個数')}</span>
                  <OsgSelect style={{width: "200px"}} onChange={ e => setMinusCount(e.target.value) } disabled={type === 'plus'}>
                    <option value={0}>0</option>
                    {(Array.from({ length: item?.quantity }, (v, k) => k)).map(v => {
                      return <option key={ v } value={ v + 1 }>{ v + 1 }</option>
                    })}
                  </OsgSelect>
                  <span style={{marginLeft: 30, color: "#427DD4", fontSize: "12px"}}>{t('※撤去する個数を選択します')}</span>
                </div>
              </RadioGroup>
            </div>
          </div>
        </div>

        <div className={classes.saveContainer}>
          <OsgButton onClick={handleSave}>
            {t('更新')}
          </OsgButton>
        </div>
      </>
      : <></> }
    </>
  );
};

export default OneByOneForm;
