import React, { createRef, useState, useEffect } from 'react';
import moment from 'moment';
import { stockOperationLog } from '../../../utils/Logger.js';
import { CSVReader } from 'react-papaparse';
import _ from 'lodash';
import axios from 'axios';
import styled from "styled-components";
import { OsgButton, OsgSelect } from "../../Common/UIcomponents";
import {
  FormControl,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  makeStyles,
  Paper,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

const DialogCloseBtn = styled(Button)`
  height: 10px !important;
  width: 50px;
  background-color: !important;
  margin: 5px 0px !important;
`;

const buttonRef = createRef();

const useStyles = makeStyles((theme) => ({
  listContainer: {
    maxWidth: 1000,
    display: "flex",
    justifyContent: "center",
    marginBottom: "50px"
  },
  listColumn: {
    display: "flex"
  },
  listName: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "20%",
    textAlign: "left",
    fontWeight: "bold",
    paddingLeft: "30px"
  },
  listSmallName: {
    display: "flex",
    alignItems: "center",
    fontSize: "10px",
    height: "20px",
    // width: "20%",
    textAlign: "left",
    // fontWeight: "bold",
    // paddingLeft: "30px"
  },
  listValue: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "60%"
  },
  header: {
    fontSize: '18px'
  },
  warning: {
    color: "#C90000",
  },
  link: {
    paddingLeft: "10px",
    color: "#00559D",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  listUpdateValue: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "60%",
    fontWeight: "bold"
  },
  tableContainer: {
    width: "74%",
    overflow: "scroll"
  },
  tableHead: {
    fontWeight: "bold",
    fontSize: "0.5vw",
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  tableBody: {
    fontSize: "0.5vw",
    whiteSpace: "nowrap",
    borderBottom: "solid 2px #CACACA"
  },
  formControl: {
    width: "100%"
  },
  registerBtnContainer: {
    marginTop: "50px",
    marginBottom: "50px",
    marginRight: "50px"
  },
  registerBtn: {
    width: "100%",
    textAlign: "right",
    marginTop: "20px",
    bottom: 15
  },
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "10px"
  },
  confirmDialogContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "10px",
    alignItems: "center",
  },
  dialogChosenMenu: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    width: "73%",
    marginTop: "15px",
    padding: "15px",
    backgroundColor: "#F6FBFF",
    borderRadius: 20
  },
  dialogTitle: {
    backgroundColor: "#00559D",
    color: "#FFFFFF",
    textAlign: "center",
    fontWeight: "bold",
    width: "20%",
    marginTop: "10px",
    paddingLeft: "29%",
    paddingRight: "29%",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  dialogDetail: {
    backgroundColor: "#FFFFFF",
    color: "#C90000",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "25px",
    width: "40%",
    paddingLeft: "15%",
    paddingRight: "15%",
    paddingTop: "15px",
    paddingBottom: "5px",
  },
  dialogCloseButton: {
    padding: "10px",
    width: "20px"
  }
}));

const CsvForm = (props) => {
  const [t] = useTranslation();
  const [customerList, setCustomerList] = useState([]);
  const classes = useStyles();

  const [boxList, setBoxList] = useState([]);
  const [updateUserList, setUpdateUserList] = useState([]);
  const [updateUserId, setUpdateUserId] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [boxId, setBoxId] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [boxName, setBoxName] = useState('');
  const today = moment().format(t('YYYY年MM月DD日(ddd)'));
  const [items, setItems] = useState([]);
  const [error, setError] = useState('');
  const [multipleItems, setMultipleItems] = useState([]);
  const [type, setType] = useState('append');
  const [header, setHeader] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false);
  const [parentUser, setParentUser] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [reserveItemCsvCount, setReserveItemCsvCount] = useState(0);
  const [minusItems, setMinusItems] = useState([]);
  const [nanItems, setNanItems] = useState([]);
  const [emptyItems, setEmptyItems] = useState([]);
  const [noExistItems, setNoExistItems] = useState([]);
  const [columnList] = useState([
    'edp_no',
    'refill_quantity'
  ]);

  useEffect(() => {
    (async () => {
      if (props.location.state?.boxId && props.location.state?.customerId ) {
        setCustomerId(props.location.state.customerId)
        const defaultCustomerList = await axios.get('/companies/');
        setCustomerList(defaultCustomerList.data);
        const customer = _.find(defaultCustomerList.data, (c) => {
          return c._id === props.location.state.customerId;
        });
        setCustomerName(customer.name)
        const res = await axios.get(`/boxes/customer/${props.location.state.customerId}`);
        setBoxList(res.data);
        const box = _.find(res.data, (c) => {
          return c._id === props.location.state.boxId;
        });
        setBoxName(box.name)
        setBoxId(props.location.state.boxId)
      } else {
        setCustomerList(props.customerList);
      }
      const user = JSON.parse(localStorage.getItem('user'));
      const parentUser = JSON.parse(localStorage.getItem('parent-user'));
      const userList = await axios.get(`/users/company/all/${user.company_id._id}`);
      setUpdateUserList(userList.data);
      setUpdateUserId(parentUser._id);
      setIsDisabled(true);
      setParentUser(parentUser);
    })();
  }, []);

  const handleSetCustomerId = async (event) => {
    setCustomerId(event.target.value);
    setBoxList(null);
    const res = await axios.get(`/boxes/customer/${event.target.value}`);
    const selectedCustomer = customerList.find(c => c._id === event.target.value);
    setCustomerName(selectedCustomer.name);
    setBoxList(res.data);
  };

  const handleSetBoxId = async (event) => {
    setBoxId(event.target.value);
    const reserveItemRes = await axios.get(`/reserve_item_csv`, {
      params: {
        box_id: event.target.value
      }
    });
    const selectedBox = boxList.find(b => b._id === event.target.value);
    setBoxName(selectedBox.name);
    setReserveItemCsvCount(reserveItemRes.data.reserveItemCsvCount)
  };

  const handleSetReserveItemCsvCount = async (event) => {
    const reserveItemRes = await axios.get(`/reserve_item_csv`, {
      params: {
        box_id: boxId
      }
    });
    setReserveItemCsvCount(reserveItemRes.data.reserveItemCsvCount)
  };

  const handleSetUpdateUserId = async (event) => {
    setUpdateUserId(event.target.value);
  };

  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = async (data) => {
    const titles = data.shift();

    let errorList = [];

    if (data[data.length - 1].data.length === 1 && data[data.length - 1].data[0] === "") {
      data.pop();
    }

    const items = [];
    _.map(data, (row) => {
      if (row.data[0] !== '') {
        items.push(row);
      }
    });
    data = items;

    const incorrectDataColumn = _.filter(data, (d) => {
      return d.data.length !== 2
    })

    if (!_.isEqual(titles.data, columnList) || incorrectDataColumn.length > 0) {
      errorList.push(t("エラー：項目の数が一致しない行があります。"))
    } else {

      const minusDataList = data.filter((raw) => {
        return raw.data[1] < 0;
      });

      const nanDataList = data.filter((raw) => {
        return String(raw.data[1]).match(/^[-+]?\d*.?\d+$/) === null && String(raw.data[1]) !== '';
      });

      const emptyDataList = data.filter((raw) => {
        return !raw.data[1];
      });

      if (titles.data[1] === 'refill_quantity' && minusDataList.length > 0) {
        errorList.push(t("エラー：マイナスの数字は登録できません"));
        setMinusItems(minusDataList);
      }
      if (titles.data[1] === 'refill_quantity' && emptyDataList.length > 0) {
        errorList.push(t("エラー：個数に空欄があります。0の場合は0を入力してください。"));
        setEmptyItems(emptyDataList);
      }
      if (titles.data[1] === 'refill_quantity' && nanDataList.length > 0) {
        errorList.push(t("エラー：数値以外の数量が入力されています。"));
        setNanItems(nanDataList);
      }

      const epdNos = data.map((raw) => {
        return raw.data[0];
      });

      const multipleEpsNos = epdNos.filter((val, i, self) => {
        return self.indexOf(val) === i && i !== self.lastIndexOf(val);
      });

      if (titles.data[0] === 'edp_no' && multipleEpsNos.length > 0) {
        setMultipleItems(multipleEpsNos)
        errorList.push(t("エラー：以下のEdp Noが重複しています。"));
      }

      // 商品の存在確認
      const res = await axios.get(`/items/validate`, {
        params: {
          edp_nos: epdNos,
          box_id: boxId
        }
      });

      if (res.data.error) {
        errorList.push(t(res.data.error));
        setNoExistItems(res.data.edp_nos);
      }
    }

    if (errorList.length === 0) {
      const items = [];
      _.map(data, (row) => {
        if (row.data[0] !== '') {
          items.push(row.data);
        }
      });
      setItems(items);
      setHeader(titles.data);
      setError(null);
      setMultipleItems([]);
      setMinusItems([]);
    } else {
      setError(errorList)
    }
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleReserveCancel = async () => {
    let options = {};
    const res = await axios.put(`/reserve_item_csv`, {
      box_id: boxId,
      customer_id: customerId,
    });

    if (res.data.reserveItemCsv.is_canceled) {
      alert(t('予約をキャンセルしました。'));
      options.items = res.data.reserveItemCsv.items;
      stockOperationLog(`在庫数量 CSVアップロード/予約補充/予約キャンセル`, boxId, options);
      setCancelDialogVisible(false);
      handleSetReserveItemCsvCount();
    }
  }

  const handleSave = async () => {
    const kv = _.map(items, (row) => {
      return _.zipObject(header, row);
    });
    let res = null;
    let options = {};
    if (type === 'update') {
      res = await axios.post(`/items/refill`, {
        box_id: boxId,
        customer_id: customerId,
        items: kv,
      });
      if (res.data.success) {
        options.items = kv;
        alert(t('補充データを反映しました'));
        setDialogVisible(false);
        stockOperationLog(`在庫数量 CSVアップロード/即時反映`, boxId, options);
        props.history.push({
          pathname: '/stocks',
          state: {
            customer_id: customerId,
            box_id: boxId
          }
        });
      } else {
        alert(t(res.data.error));
        setDialogVisible(false);
      }
    } else {
      res = await axios.post(`/reserve_item_csv`, {
        box_id: boxId,
        customer_id: customerId,
        update_user_id: updateUserId,
        items: kv,
      });
      if (res.data.success) {
        alert(t('補充データの予約が完了しました'));
        setDialogVisible(false);
        handleSetReserveItemCsvCount();
        options.items = kv;
        stockOperationLog(`在庫数量 CSVアップロード/予約補充/予約完了`, boxId, options);
        props.history.push({
          pathname: '/stocks',
          state: {
            customer_id: customerId,
            box_id: boxId
          }
        });
      } else {
        alert(t(res.data.error));
        setDialogVisible(false);
      }
    }
    setItems(0)
  };

  const handleSetType = (event) => {
    setIsDisabled(false);
    setType(event.target.value);
  };

  return (
    <>
      <div className={classes.listContainer}>
        <dl style={{width: "100%", margin: 0}}>
          <div className={classes.listColumn} style={{marginTop: "20px"}}>
            <dt className={classes.listName}>
              <h2 className={classes.header}>{t("在庫数量 更新")}</h2>
            </dt>
            <dd className={classes.listUpdateValue}>
              {(reserveItemCsvCount > 0) ? (
                <>
                  <span className={classes.warning}>{t('このBOXは予約中のデータがあるので、更新は出来ません。')}</span>
                  <span className={classes.link} onClick={() => setCancelDialogVisible(true)}>-&gt;{t('予約を取り消す')}</span>
                </>
              ) : null}
            </dd>
          </div>
          <div className={classes.listColumn} style={{marginTop: "20px"}}>
            <dt className={classes.listName}>{t("更新日")}</dt>
            <dd className={classes.listValue}>{today}</dd>
          </div>

          <div className={classes.listColumn} style={{marginTop: "20px"}}>
            <dt className={classes.listName}>{t("補充者名")}</dt>
            <dd className={classes.listValue}>{parentUser.username}</dd>
          </div>

          <div className={classes.listColumn} style={{marginTop: "20px"}}>
            <dt className={classes.listName}>{t("会社名")}</dt>
            <dd className={classes.listValue}>
              <FormControl className={classes.formControl}>
                <OsgSelect
                  value={customerId}
                  onChange={handleSetCustomerId}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  style={{width: '254px'}}
                >
                  <option value=""> - </option>
                  {customerList.map((row) => {
                    return <option key={ row._id } value={row._id}>{row.name}</option>;
                  })}
                </OsgSelect>
              </FormControl>
            </dd>
          </div>

          <div className={classes.listColumn} style={{marginTop: "20px"}}>
            <dt className={classes.listName}>{t("補充するBOXを選択")}</dt>
            <dd className={classes.listValue}>
              {(boxList) ? (
                <FormControl className={classes.formControl}>
                  <OsgSelect
                    value={boxId}
                    onChange={handleSetBoxId}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{width: '254px'}}
                  >
                    <option value=""> - </option>
                    {boxList.map((row) => {
                      return <option key={ row._id } value={row._id}>{row.name}</option>;
                    })}
                  </OsgSelect>
                </FormControl>
              ) : (
                <div style={{ width: "254px", justifyContent: 'center', display: 'flex'}}>
                  <CircularProgress size="2rem" />
                </div>
              )}
            </dd>
          </div>

          <div className={classes.listColumn} style={{marginTop: "20px"}}>
            <dt className={classes.listName}></dt>
            <dd className={classes.listValue}>
              <CSVReader
                ref={buttonRef}
                onFileLoad={handleOnFileLoad}
                onError={handleOnError}
                config={{
                  encoding: 'sjis',
                }}
                noClick
                noDrag
              >
                {({ file }) => (
                  <aside>
                    <OsgButton
                      onClick={handleOpenDialog}
                      disabled={reserveItemCsvCount > 0 || !boxId}
                    >
                    {t("アップロード")}
                    </OsgButton>
                    <span style={{marginLeft: "20px"}}>{file && file.name}</span>
                  </aside>
                )}
              </CSVReader>
            </dd>
          </div>
          {(error) ? (
            <>
              {error.map(e =>
                <div className={classes.listColumn} style={{marginTop: "10px"}}>
                  <dt className={classes.listName}></dt>
                  <dd className={classes.listValue}>
                      <Typography>
                        <span className="error-text">
                          {e}
                          {(e === t("エラー：マイナスの数字は登録できません")) ? (
                            <>
                              {minusItems.map(i => ` ${i.data[0]},`)}
                            </>
                          ) : (e === t("エラー：数値以外の数量が入力されています。")) ? (
                            <>
                              {nanItems.map(i => ` ${i.data[0]},`)}
                            </>
                          ) : (e === t("エラー：個数に空欄があります。0の場合は0を入力してください。")) ? (
                            <>
                              {emptyItems.map(i => ` ${i.data[0]},`)}
                            </>
                          ) : (e === t("エラー：以下のEdp Noが重複しています。")) ? (
                            <>
                              {multipleItems.map(i => ` ${i},`)}
                            </>
                          ) : (e === t("エラー：存在しない商品があります")) ? (
                            <>
                              {noExistItems.map(i => ` ${i},`)}
                            </>
                          ) : null}
                        </span>
                      </Typography>
                  </dd>
                </div>
              )}
            </>
          ) : null}
        </dl>
      </div>

      {items.length > 0 ? (
        <>
          <h4>{t("プレビュー")}</h4>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table size="small" aria-label="item table">
              <TableHead>
                <TableRow>
                  {header.map((title, index) => (
                    <TableCell
                      className={classes.tableHead}
                      key={ index }
                      style={index % 2 ? {backgroundColor: "#939393"} : {backgroundColor: "#BEBEBE"}}
                    >
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((row) => (
                  <TableRow>
                    {row.map((value, index) => (
                      <TableCell
                        className={classes.tableBody}
                        key={ index }
                        style={index % 2 ? {backgroundColor: "#DFDFDF"} : {backgroundColor: "#F5F5F5"}}
                        align="center"
                      >
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.registerBtnContainer}>
            <div className={classes.registerBtn}>
              <OsgButton
                onClick={() => setDialogVisible(true)}
                disabled={reserveItemCsvCount > 0}
              >
                {t("登録する")}
              </OsgButton>
            </div>
          </div>
        </>
      ) : null}

      <Dialog open={cancelDialogVisible} fullWidth maxWidth="sm">
        <div className={classes.dialogContainer}>
          <div style={{fontWeight: "bold", marginTop: "10px", marginBottom: "20px", textAlign: "center" }}>
            {t("予約中のデータを反映せずに取り消しますか？")}
          </div>

          <div className={classes.buttonContainer} style={{marginTop: "20px", textAlign: "right" }}>
            <Button
              color="secondary"
              variant="text"
              onClick={() => {
                setCancelDialogVisible(false)
                handleReserveCancel()
              }}
              style={{height: "42px", marginRight: "0", color: "#C90000"}}
            >
              {t("予約の取り消し")}
            </Button>
            <Button
              variant="text"
              onClick={ () => setCancelDialogVisible(false) }
              style={{color: "#000000"}}
            >
              {t("キャンセル")}
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog open={dialogVisible} fullWidth maxWidth="sm">
        <div className={classes.confirmDialogContainer}>
          <div style={{fontSize: "20px", fontWeight: "bold"}}>
            {boxName}
          </div>

          <div style={{fontSize: "15px", color: "#C4C4C4"}}>
            {customerName}
          </div>

          <div className={classes.dialogTitle}>
            {t("在庫項目の更新")}
          </div>

          <div className={classes.dialogDetail}>
            {t("更新点数")}&nbsp;&nbsp;&nbsp;{t("合計")}{items.length}{t("点")}
          </div>

          <div className={classes.dialogChosenMenu}>
            <RadioGroup
              row
              aria-label="position"
              name="type"
              defaultValue="append"
              onChange={handleSetType}
              style={{flexDirection: "column", marginTop: "10px"}}
            >
              <FormControlLabel
                value="update"
                control={<Radio color="primary" />}
                label={t("即時反映")}
                labelPlacement="end"
              />
              <FormControlLabel
                value="reserve"
                control={<Radio color="primary" />}
                label={t("補充予約")}
                labelPlacement="end"
              />
            </RadioGroup>
            <div className={classes.listColumn} style={{ flexDirection: "column", marginTop: "20px"}}>
              <div className={classes.listSmallName}>{t("データ反映用メールを受け取る人")}</div>
              <div className={classes.listValue}>
                <FormControl className={classes.formControl}>
                  <OsgSelect
                    value={updateUserId}
                    onChange={handleSetUpdateUserId}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{width: '350px'}}
                    disabled={!type || type === "update"}
                  >
                    {(updateUserList) ? (
                      updateUserList.map((row) => {
                        return <option key={ row._id } value={row._id}>{row.username}</option>;
                      }
                    )) : null}
                  </OsgSelect>
                </FormControl>
              </div>
            </div>
          </div>

          <div style={{marginTop: "20px"}}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setDialogVisible(false)}
              style={{width: "200px", height: "42px", marginRight: "20px"}}
            >
              {t("戻る")}
            </Button>
            <OsgButton
              onClick={(e) => {
                setIsDisabled(true)
                handleSave(e)
              }}
              disabled={isDisabled}
            >
            {t("登録する")}
            </OsgButton>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default CsvForm;
